import { render, staticRenderFns } from "./SeoQuestions.htm?vue&type=template&id=6601820a&scoped=true&"
import script from "./SeoQuestions.ts?vue&type=script&lang=js&"
export * from "./SeoQuestions.ts?vue&type=script&lang=js&"
import style0 from "./SeoQuestions.scss?vue&type=style&index=0&id=6601820a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6601820a",
  null
  
)

export default component.exports