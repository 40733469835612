import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BlogPostCard from '@/partials/BlogPostCard/BlogPostCard.vue'
import Post from '@/models-ts/blog/Post'
import paginalMixin from '@/mixins/paginalMixin'
import SeoQuestions from '@/partials/SeoQuestions/SeoQuestions.vue'
import MainPost from './MainPost/MainPost.vue'
import questions from './questions'

export default Vue.extend<any, any, any, any>({
  mixins: [paginalMixin],
  components: {
    MainPost,
    BlogPostCard,
    SeoQuestions,
  },
  data () {
    return {
      pageSize: 48,
      questions,
    }
  },
  computed: {
    ...mapGetters({
      posts: 'blog/posts',
      mainPost: 'blog/mainPost',
    }),
    ...mapState('blog', {
      isLoaded: (state: any) => state.blog.isLoaded,
      isLoading: (state: any) => state.blog.isLoading,
      pagination: (state: any) => state.blog.pagination,
      prefetched: (state: any) => state.prefetched,
    }),
    secondPosts () {
      return this.posts.filter((post: Post) => post.id !== this.mainPost?.id)
    },
    metaTitle () {
      const title = 'How to Earn Crypto With LaborX'
      return this.currentPage > 1 ? `${title} | Page ${this.currentPage}` : title
    }
  },
  async prefetch () {
    const pageFromQuery = Number(this.$route.query?.page || 0)
    try {
      if (process.server) {
        this.setPrefetched(true)
        await this.load({ offset: (this.currentPage - 1) * this.pageSize })
        if (this.currentPage > this.totalPages) {
          this.currentPage = this.totalPages
          await this.load({ offset: (this.currentPage - 1) * this.pageSize })
        }
      }
      if (pageFromQuery > this.totalPages) {
        const curPage = this.totalPages > 1
          ? `?page=${this.totalPages}`
          : ''
        this.setRedirect(`${this.$route.path}${curPage}`)
      }
      if (pageFromQuery === 1) {
        this.setRedirect(this.$route.path)
      }
    } catch (e) {
      console.error('Prefetch Blog error:', e)
    }
  },
  mounted () {
    this.anchor = this.$el
  },
  methods: {
    ...mapMutations({
      setPrefetched: 'blog/setPrefetched',
      setMainPost: 'blog/setMainPost',
      setRedirect: 'app/setRedirect',
    }),
    ...mapActions({
      getPosts: 'blog/getPosts',
      getMainPost: 'blog/getMainPost',
    }),
    async load ({ offset }: { offset: number }) {
      if (this.currentPage === 1) {
        if (this.mainPost) {
          await this.getPosts({ limit: this.pageSize, offset: 1 })
        } else {
          await this.getPosts({ limit: this.pageSize + 1, offset: 0 })
          this.setMainPost(this.posts[0])
        }
      } else {
        await Promise.all([
          this.getPosts({ limit: this.pageSize, offset: offset + 1 }),
          this.loadMainPost()
        ])
      }
    },
    async loadMainPost () {
      try {
        if (!this.mainPostLoading && !this.mainPost) {
          this.mainPostLoading = true
          await this.getMainPost()
          this.mainPostLoading = false
        }
      } catch (e) {
        console.error('Error loading post', e)
        this.mainPostLoading = false
      }
    }
  },
  metaInfo () {
    return {
      title: this.metaTitle,
      titleTemplate: '%s',
      meta: [{
        vmid: 'description',
        name: 'description',
        // eslint-disable-next-line max-len
        content: 'The cryptocurrency space is growing fast, with more and more opportunities appearing on crypto job boards every day. Learn how to find a remote job and start earning cryptocurrency!',
      }],
      link: [this.canonicalLink]
    }
  },
})
