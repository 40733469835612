import Vue from 'vue'
import Post from '@/models-ts/blog/Post'
import { DATE_TIME_FORMAT } from '@/constants/utils/date'
import { POST } from '@/constants/routes'
import { replaceUploadUrl } from '@/utils/file'
import { formatDate, convertToLocal } from '@/utils/date'

export default Vue.extend({
  props: {
    post: Post
  },
  data () {
    return {
      POST
    }
  },
  methods: {
    replaceUploadUrl,
    getPublishedDate (post: Post) {
      return convertToLocal(post.publishAt, 'MMM, DD YYYY')
    },
    getPublishedDateUTC (post: Post) {
      return formatDate(post.publishAt, DATE_TIME_FORMAT)
    },
  },
})
