import Vue from 'vue'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import { ScriptPropertyJson } from 'vue-meta'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BlogPostCard from '@/partials/BlogPostCard/BlogPostCard.vue'
import ErrorMatcher from '@/utils/ErrorMatcher'
import Post, { PostQuestion } from '@/models-ts/blog/Post'
import { BLOG, LANDING } from '@/constants/routes'
import { DATE_TIME_FORMAT } from '@/constants/utils/date'
import { convertToLocal, formatDate } from '@/utils/date'
import { replaceUploadUrl } from '@/utils/file'
import canonicalMixin from '@/mixins/canonicalMixin'
import LandingMobileStepper from '@/pages/Landing/LandingMobileStepper/LandingMobileStepper.vue'

export default Vue.extend<any, any, any, any>({
  mixins: [canonicalMixin],
  components: {
    BlogPostCard,
    LandingMobileStepper,
  },
  data () {
    return {
      activeQuestions: [],
      stepIndent: 0,
    }
  },
  computed: {
    ...mapGetters({
      lastPosts: 'blog/lastPosts',
    }),
    post () {
      return this.$store.getters['blog/post'](this.$route.params.slug)
    },
    isLoading () {
      return this.$store.getters['blog/postIsLoading'](this.$route.params.slug)
    },
    isLoaded () {
      return this.$store.getters['blog/postIsLoaded'](this.$route.params.slug)
    },
    otherPosts () {
      if (this.post && this.lastPosts) {
        return this.lastPosts.filter((post: Post) => post.id !== this.post.id).slice(0, 3)
      }
      return []
    },
    breadcrumbs () {
      return [
        {
          title: 'Home',
          to: { name: LANDING }
        },
        {
          title: 'Blog',
          to: { name: BLOG }
        },
        {
          title: this.post ? this.post.name : '',
        }
      ]
    },
    publishedDate () {
      return convertToLocal(this.post.publishAt, 'MMM, DD YYYY')
    },
    publishedDateUTC () {
      return formatDate(this.post.publishAt, DATE_TIME_FORMAT)
    },
  },
  watch: {
    '$route.params.slug': {
      handler () {
        if (this.$route.params.slug) {
          this.getPost(this.$route.params.slug)
        }
      }
    },
    post (val) {
      if (val) {
        this.addAnchorLinkEvent()
      }
    },
  },
  async prefetch () {
    try {
      if (process.server) {
        await this.getPost(this.$route.params.slug)
        await this.getOtherPosts()
      }
    } catch (e) {
      if (ErrorMatcher.isNotFound(e)) {
        this.setNotFound(true)
      } else {
        console.error('Error post fetching', e)
      }
    }
  },
  async created () {
    if (process.client) {
      if (!this.post) {
        this.getPost(this.$route.params.slug)
      }
      if (this.otherPosts) {
        this.getOtherPosts()
      }
    }
  },
  mounted () {
    if (this.post) {
      this.addAnchorLinkEvent()
    }
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    replaceUploadUrl,
    ...mapMutations({
      setNotFound: 'app/setNotFound',
    }),
    ...mapActions({
      getPost: 'blog/getPost',
      getOtherPosts: 'blog/getOtherPosts',
    }),
    toggleQuestion (i: number) {
      const elIndex = this.activeQuestions.indexOf(i)
      if (elIndex === -1) {
        this.activeQuestions.push(i)
      } else {
        this.activeQuestions.splice(elIndex, 1)
      }
    },
    addAnchorLinkEvent () {
      this.$nextTick(() => {
        const links = this.$refs.text.querySelectorAll('a')
        for (const link of links) {
          if (link.getAttribute('href').charAt(0) === '#') {
            link.addEventListener('click', (event: MouseEvent) => {
              event.preventDefault()
              if (event.currentTarget) {
                this.$router.push({ hash: (event.currentTarget as HTMLElement).getAttribute('href') })
              }
            })
          }
        }
      })
    },
    alignPostCard (behavior?: 'smooth' | 'auto') {
      const postsContainer = this.$el.querySelector('.other-posts-container')
      if (postsContainer) {
        if (postsContainer.scrollLeft > postsContainer.clientWidth / 2 + 10) {
          this.scrollTo(1, behavior)
        } else {
          this.scrollTo(0, behavior)
        }
      }
    },
    scrollTo (slide: number, behavior?: 'smooth' | 'auto') {
      const postsContainer = this.$el.querySelector('.other-posts-container')
      if (postsContainer) {
        if (slide === 1) {
          postsContainer.scrollTo({ left: postsContainer.scrollWidth, behavior })
        } else {
          postsContainer.scrollTo({ left: 0, behavior })
        }
      }
    },
    onResize: debounce(function (this: any) { this.alignPostCard('auto') }, 30),
    onScroll: throttle(function (this: any) {
      const postsContainer = this.$el.querySelector('.other-posts-container')
      if (postsContainer) {
        this.stepIndent = (postsContainer.scrollLeft / (postsContainer.clientWidth + 20)) * 40
      }
    }, 30),
  },
  metaInfo () {
    const meta = []
    const script: Array<ScriptPropertyJson> = []
    if (this.post) {
      meta.push({
        vmid: 'description',
        name: 'description',
        content: this.post.seo_description || this.post.description
      })
      meta.push({
        vmid: 'og:title',
        property: 'og:title',
        content: this.post.name
      })
      meta.push({
        vmid: 'og:description',
        property: 'og:description',
        content: this.post.description
      })
      meta.push({
        vmid: 'og:image',
        property: 'og:image',
        content: this.post.poster
      })
      meta.push({
        vmid: 'og:type',
        property: 'og:type',
        content: 'article',
      })
      meta.push({
        vmid: 'article:published_time',
        property: 'article:published_time',
        content: formatDate(this.post.publishAt, '')
      })
      meta.push({
        vmid: 'article:modified_time',
        property: 'article:modified_time',
        content: formatDate(this.post.updated_at, '')
      })
      meta.push({
        vmid: 'article:author',
        property: 'article:author',
        content: 'LaborX'
      })

      script.push({
        vmid: 'BlogPosting',
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'BlogPosting',
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`
          },
          headline: this.post.name,
          description: this.post.description,
          image: [this.post.poster],
          datePublished: formatDate(this.post.publishAt, ''),
          dateModified: formatDate(this.post.updated_at, ''),
          author: {
            '@type': 'Organization',
            name: 'LaborX',
            logo: {
              '@type': 'ImageObject',
              url: `${process.env.VUE_APP_FRONTEND_URL}/static/favicon/android-chrome-512x512.png`,
              width: 512,
              height: 512,
            }
          },
          publisher: {
            '@type': 'Organization',
            name: 'LaborX',
            logo: {
              '@type': 'ImageObject',
              url: `${process.env.VUE_APP_FRONTEND_URL}/static/favicon/android-chrome-512x512.png`,
              width: 512,
              height: 512,
            }
          }
        }
      })

      if (this.post.questions.length) {
        script.push({
          vmid: 'FAQPage',
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: this.post.questions.map((question: PostQuestion) => ({
              '@type': 'Question',
              name: question.question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: question.answer
              }
            }))
          }
        })
      }
    }

    return {
      title: this.post ? (this.post.seo_title || this.post.name) : 'Loading',
      meta,
      script,
      link: [this.canonicalLink],
    }
  },
})
