import isEqual from 'lodash/isEqual'
import animateScrollTo from '@/utils/animateScrollTo'
import responseMixin from '@/mixins/responseMixin'
import canonicalMixin from '@/mixins/canonicalMixin'

const PAGE_SIZE = 20

export default {
  mixins: [responseMixin, canonicalMixin],
  data () {
    return {
      anchor: null, // NodeElement to wich is scrolled after change of page
      currentPage: 1,
      pageSize: PAGE_SIZE,
      navigationPagination: true,
    }
  },
  computed: {
    pagination () {
      return { total: 0 }
    },
    totalPages () {
      return Math.ceil(this.pagination.total / this.pageSize) || 1
    },
    canonicalLink () {
      const url = this.getCanonicalUrl(this.$route)
      if (Number(url.searchParams.get('page')) === 1) {
        url.searchParams.delete('page')
      }
      return {
        vmid: 'canonical',
        rel: 'canonical',
        href: url.href,
      }
    }
  },
  created () {
    if (this.navigationPagination) {
      this.currentPage = this.getPageFromQuery()
    }
  },
  async mounted () {
    if (this.navigationPagination) {
      if (this.prefetched && this.isLoaded && this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages
        await this.$router.replace({
          name: this.$route.name,
          params: this.$route.params,
          query: { ...this.$route.query, page: this.currentPage },
        })
      }
      this.$watch('$route', (newR, oldR) => {
        if (!isEqual({ query: newR.query, params: newR.params }, { query: oldR.query, params: oldR.params })) {
          const page = this.getPageFromQuery()
          this.load({
            limit: this.pageSize,
            offset: Math.max((page - 1) * this.pageSize, 0),
          })
        }
      })
    }
    // Has prefetched data
    if (this.prefetched && this.isLoaded) {
      this.setPrefetched(false)
      return
    }
    await this.load({ limit: this.pageSize, offset: Math.max((this.currentPage - 1) * this.pageSize, 0) }) // ToDo: catch exception
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages
      if (this.navigationPagination) {
        this.$router.replace({
          name: this.$route.name,
          params: this.$route.params,
          query: { ...this.$route.query, page: this.currentPage },
        })
      } else {
        await this.load({ limit: this.pageSize, offset: Math.max((this.currentPage - 1) * this.pageSize, 0) }) // ToDo: catch exception
      }
    }
  },
  methods: {
    setPrefetched () { /** for implementation */ },
    load () { /** for implementation */ },
    getPageFromQuery () {
      const page = this.$route.query.page
      if (Number.isNaN(Number(page))) {
        return 1
      }
      return Math.max(Number.parseInt(page), 1)
    },
    async refreshList () {
      this.load({ limit: this.pageSize, offset: (this.currentPage - 1) * this.pageSize })
    },
    async onChangePage (page) {
      const oldPage = this.currentPage
      try {
        this.currentPage = page
        if (this.anchor) {
          const el = typeof this.anchor === 'string' ? document.querySelector(this.anchor) : this.anchor
          animateScrollTo(el)
        }
        if (!this.navigationPagination) {
          // ToDo: solve the problem with concurrent async request
          await this.load({ limit: this.pageSize, offset: (page - 1) * this.pageSize })
        }
      } catch (e) {
        this.currentPage = oldPage
        this.parseError(e)
      }
    }
  },
}
