import { render, staticRenderFns } from "./MainPost.htm?vue&type=template&id=cbb2b9cc&scoped=true&"
import script from "./MainPost.ts?vue&type=script&lang=js&"
export * from "./MainPost.ts?vue&type=script&lang=js&"
import style0 from "./MainPost.scss?vue&type=style&index=0&id=cbb2b9cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbb2b9cc",
  null
  
)

export default component.exports