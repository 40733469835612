/* eslint-disable max-len */
export default [
  {
    question: 'What are crypto jobs?',
    answer: 'Crypto jobs are positions within the crypto, blockchain, and Web3 space. These may involve working directly with decentralised technologies, or they may refer to jobs with companies that operate in this space – whether or not the role involves actually understanding and using blockchain. For example, a smart contract developer, who builds applications that run on the blockchain, a marketing specialist who promotes the product, and an administrator for the company, can all be considered ‘crypto jobs’.'
  },
  {
    question: 'How much do crypto jobs pay?',
    answer: 'Jobs in the crypto sector are often well paid. Due to the shortage of qualified and experienced candidates, you can expect your salary to be anything up to 20% higher than in comparable roles in other industries, though the numbers vary. Even internships and entry-level jobs are likely to be reasonably well paid. At the higher end, if the job requires extensive experience (for example, smart contract developer for a top DeFi protocol), salaries can be $250k+.',
  },
  {
    question: 'What are the best jobs in crypto in 2024',
    answer: 'The highest-paying jobs in the crypto and blockchain sector are technical roles. Senior developers and smart contract developers are some of the best-paid positions, with salaries typically being $150,000–$250,000, and even higher for the most experienced professionals. Marketing roles are also generally well paid, and numerous jobs have six-figure salaries due to the complexity of the area and the lack of qualified people.',
  },
]
